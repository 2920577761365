import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { IEnvironment } from 'src/environments/environment.model';
import { SettingsService } from 'src/app/modules/core/services/settings.service';
import { UnsubscribeOnDestroy } from 'src/app/modules/shared/utilities/unsubscribe-on-destroy';
import { WebsiteCommunity } from 'src/app/modules/core/models/sales.model';

@Injectable({
	providedIn: 'root'
})
export class OrgService extends UnsubscribeOnDestroy {	
	private settings!: IEnvironment;

	constructor(private _http: HttpClient,
		settingsService: SettingsService) {
		super();

		settingsService.getSettings().pipe(
			this.takeUntilDestroyed()
		).subscribe(settings => {
			this.settings = settings;
		});
	}

	getCommunityDetails(financialCommunityId: number): Observable<WebsiteCommunity | null> {
		const url = `${this.settings?.apiUrl}org/communityDetails/${financialCommunityId}`;
		return this._http.get<WebsiteCommunity>(url);
	}
}