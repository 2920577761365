import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { BrandService } from 'src/app/modules/core/services/brand.service';
import { SettingsService } from 'src/app/modules/core/services/settings.service';

import { TERMS_ACKNOWLEDGED } from 'src/app/modules/shared/constants/local-storage';
import { UnsubscribeOnDestroy } from 'src/app/modules/shared/utilities/unsubscribe-on-destroy';

@Component({
	selector: 'cp-terms-of-use-modal',
	templateUrl: './terms-of-use-modal.component.html',
	styleUrls: ['./terms-of-use-modal.component.scss'],
	standalone: false
})
export class TermsOfUseModalComponent extends UnsubscribeOnDestroy {
	agreedToTerms: boolean = false;
	brandName: string;
	bulletPoints: string[];
	termsOfUseUrl: string = '';

	constructor(
		public dialogRef: MatDialogRef<TermsOfUseModalComponent>,
		private brandService: BrandService,
		private settingsService: SettingsService
	) {
		super();

		this.brandName = this.brandService.getBrand().title;
		this.bulletPoints = [
			`All home designs, floor plans, data, images, photographs and other information displayed in this Customer Portal is the property of ${this.brandName} and is subject to removal or change at any time without notice.`,
			'Home elevation drawings and images are artist\'s renderings and not construction plans or intended to show specific detailing. Floor plans are for illustrative purposes only and may differ from the actual features and measurements of the completed home. Features may vary depending on choice of options and variances may occur due to field conditions or need to comply with governmental requirements. Any dimensions and square footages shown are approximate.',
			'This portal provides only general updates regarding the construction progress of your home. Construction will progress according to our construction schedule. Due to many factors and uncertainties, including weather, the availability of materials and the scheduling of contractors, that schedule is subject to change by us. Any dates provided for construction timing, completion or closing are just estimates, may not be relied upon, and may change without notice.',
			'Information shown regarding the terms of your home purchase is provided as a courtesy. The Total Purchase Price for your Property will be equal to the Base Price of the Property plus the price of all options, upgrades and premiums (as those amounts are set forth in the current Job Initiation Order or Change Order to your Home Purchase Agreement) and may be adjusted as set forth in your Home Purchase Agreement. See your Home Purchase Agreement and current Job Initiation Order or Change Order for more details regarding pricing. If there is any difference between that information shown and the terms of your Home Purchase Agreement, the terms of the Home Purchase Agreement, including the latest JIO or Change Order, will control. Nothing contained in this portal shall amend the terms of your Home Purchase Agreement.'
		];

		this.settingsService.getSettings()
			.pipe(this.takeUntilDestroyed())
			.subscribe(settings => {
				this.termsOfUseUrl = settings.brandConfigs[this.brandService.getBrand().name].termsOfUseUrl;
			});
	}

	agreeToTerms(): void {
		localStorage.setItem(TERMS_ACKNOWLEDGED, String(this.agreedToTerms));
		this.dialogRef.close();
	}
}