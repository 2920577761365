/* The Core Module is for singleton services, app-level components.
Anything that is used or exists once and isn't part of a feature module */
import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';

import { SharedModule } from 'src/app/modules/shared/shared.module';

import { CustomerPortalComponent } from 'src/app/modules/core/components/customer-portal/customer-portal.component';
import { ErrorPageComponent } from 'src/app/modules/core/components/error-page/error-page.component';
import { FooterComponent } from 'src/app/modules/core/components/footer/footer.component';
import { HeaderComponent } from 'src/app/modules/core/components/header/header.component';
import { ModalComponent } from 'src/app/modules/core/components/modal/modal.component';
import { NavigationComponent } from 'src/app/modules/core/components/navigation/navigation.component';
import { TermsOfUseModalComponent } from 'src/app/modules/core/components/terms-of-use-modal/terms-of-use-modal.component';

import { AuthService } from 'src/app/modules/core/services/auth.service';
import { ApplicationInsightsService } from 'src/app/modules/core/services/telemetry.service';
import { BrandService } from 'src/app/modules/core/services/brand.service';
import { DialogService } from 'src/app/modules/core/services/dialog.service';
import { FunctionTimeoutService } from 'src/app/modules/core/services/function-timeout.service';
import { JobService } from 'src/app/modules/core/services/job.service';
import { OrgService } from 'src/app/modules/core/services/org.service';
import { SalesService } from 'src/app/modules/core/services/sales.service';
import { SettingsService } from 'src/app/modules/core/services/settings.service';
import { StorageService } from 'src/app/modules/core/services/storage.service';
import { InternalUrlPipe } from 'src/app/modules/shared/pipes/internal-url.pipe';

@NgModule({
	declarations: [
		CustomerPortalComponent,
		ErrorPageComponent,
		FooterComponent,
		HeaderComponent,
		ModalComponent,
		NavigationComponent,
		TermsOfUseModalComponent
	],
	imports: [
		CommonModule,
		MatButtonModule,
		MatDialogModule,
		MatIconModule,
		MatListModule,
		MatMenuModule,
		MatSidenavModule,
		RouterModule,
		SharedModule,
		PortalModule
	],
	providers: [
		AuthService,
		ApplicationInsightsService,
		BrandService,
		DialogService,
		FunctionTimeoutService,
		JobService,
		OrgService,
		SalesService,
		SettingsService,
		StorageService,
		InternalUrlPipe
	],
	exports: [
		HeaderComponent,
		NavigationComponent,
		CustomerPortalComponent
	]
})
export class CoreModule { }