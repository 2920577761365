import { Component } from '@angular/core';

import { Observable } from 'rxjs';

import { BrandService } from 'src/app/modules/core/services/brand.service';
import { SettingsService } from 'src/app/modules/core/services/settings.service';

import { AppFacade } from 'src/app/modules/ngrx-store/app/app.facade';

import { UnsubscribeOnDestroy } from 'src/app/modules/shared/utilities/unsubscribe-on-destroy';

@Component({
	selector: 'cp-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
	standalone: false
})
export class FooterComponent extends UnsubscribeOnDestroy {
	disclaimers$: Observable<string[]> = this.appFacade.disclaimers$;

	currentYear: number = new Date().getFullYear();
	privacyPolicyUrl = '';
	termsOfUseUrl = '';

	constructor(
		private appFacade: AppFacade,
		private brandService: BrandService,
		private settingsService: SettingsService,
	) {
		super();

		this.settingsService.getSettings().pipe(
			this.takeUntilDestroyed()
		).subscribe(settings => {
			const brand = this.brandService.getBrand();

			this.privacyPolicyUrl = settings.brandConfigs[brand.name].privacyPolicyUrl;
			this.termsOfUseUrl = settings.brandConfigs[brand.name].termsOfUseUrl;
		});
	}
}